<template>
	<div>
		<el-dialog title="群管理" :visible.sync="visiable" width="360px" custom-class="group">
			<div class="select-content"  v-if="Object.keys(data).length > 0">
				<div class="group-item">
					<li>
						<p>全员禁言</p>
						<p>
							<el-switch v-model="data.group.is_msg ==1" inactive-color="#dcdfe6" active-color="#409eff"	@change="groupIsMsg(!data.group.is_msg)"></el-switch>
							
						</p>
					</li>
					<li>
						<p>群内禁止好友</p>
						<p>
							<el-switch v-model="data.group.edit_photo ==1" inactive-color="#dcdfe6" active-color="#409eff"	@change="editChange(!data.group.edit_photo)"></el-switch>
						</p>
					</li>
					<li @click="handleGroupSetManger()">
						<p>设置管理员</p>
						<p><span></span><i></i></p>
					</li>
					<li  @click="handleGroupTransfer()">
						<p>群主转让</p>
						<p><span></span><i></i></p>
					</li>
					<li  @click="handleInvite()">
						<p>邀请成员</p>
						<p><span></span><i></i></p>
					</li>
					<li  @click="handleGroupKickout()">
						<p>踢出成员</p>
						<p><span></span><i></i></p>
					</li>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
			</span>
		</el-dialog>
		<groupTransfer ref="groupTransfer"></groupTransfer>
		<groupSetManager ref="groupSetManager"></groupSetManager>
		<groupKickout  ref="groupKickout" @on-back="groupKickoutBack"></groupKickout>
		<selectUser
		  ref="selectInvite"
		  title="邀请新成员"
		  tip=""
		  :default="defaultList"
		  @handleOk="handleInvited"
		></selectUser>
	</div>
</template>

<script>
	import { getChatDetails, groupIsMsg, editChange, addChat, getGroupAdmin } from '@/api'
	import { mapState, mapActions} from 'vuex'
	import { Message } from 'element-ui';
	import groupTransfer from '@/components/group/groupTransfer.vue'
	import groupSetManager from '@/components/group/groupSetManager.vue'
	import groupKickout from '@/components/group/groupKickout.vue'
	import selectUser from '@/components/selectUser/index.vue'
	export default {
		components: {
			groupTransfer,
			groupSetManager,
			groupKickout,
			selectUser
		},
		data() {
			return {
				data: {},
				visiable: false,
				defaultList:[]
			}
		},
		computed: {
			...mapState([
				'selectId',
				'groupDetail'
			]),
		},
		created() {},
		methods: {
			...mapActions(['setGroupDetail','fetchCharList']),
			getGroupAdmin() {
				this.defaultList= [];
				getGroupAdmin({
				  list_id: this.selectId,
				  type:3
				}).then(res => {
					for(let i in res.data.list){
						for(let j in res.data.list[i].data){
							this.defaultList.push(res.data.list[i].data[j].user_id)
						}
						
					}
				})
			},
			
			groupIsMsg (value) {
			  const data = {
			    list_id: this.selectId,
			    value: value ? '1' : '0'
			  }
			  this.data.group.is_msg = value ? 1 : 0;
			  this.groupDetail.group.is_msg = this.data.group.is_msg;
			  this.setGroupDetail(this.groupDetail);
			  groupIsMsg(data).then(res => {
				
			  })
			},
			editChange (value) {
			  const data = {
			    list_id: this.selectId,
			    value: value ? '1' : '0'
			  }
			  this.data.group.edit_photo = value ? 1 : 0;
			  this.groupDetail.group.edit_photo = this.data.group.edit_photo;
			  this.setGroupDetail(this.groupDetail);
			  editChange(data).then(res => {
				
			  })
			},
			handleGroupTransfer() {
				if(this.data.is_action != 2){
					Message({
					   showClose: true,
					   message: "没有权限设置",
					   type: 'error',
					   duration: 1000
					})
					
					return;
				}
				this.$refs.groupTransfer.open()
			},
			handleGroupSetManger() {
				if(this.data.is_action != 2){
					Message({
					   showClose: true,
					   message: "没有权限设置",
					   type: 'error',
					   duration: 1000
					})
					
					return;
				}
				this.$refs.groupSetManager.open()
			},
			handleGroupKickout() {
				if(this.data.is_action != 2){
					Message({
					   showClose: true,
					   message: "没有权限设置",
					   type: 'error',
					   duration: 1000
					})
					
					return;
				}
				this.$refs.groupKickout.open()
			},
			groupKickoutBack(){
				 this.getGroupAdmin();
			},

			handleInvite () {
			
			  this.$refs.selectInvite.open(this.defaultList)
			},
			handleInvited (data) {
			  const users = []
			  data.forEach(item => {
			    if (!this.defaultList.includes(item.user_id)) {
			      users.push(item.user_id)
			    }
			  })
			  addChat({
			    users: JSON.stringify(users),
			    list_id: this.selectId
			  }).then(res => {
				
			  })
			  this.getGroupAdmin();
			  	this.fetchCharList(this.selectId || '');
			},
			open() {
				this.getGroupAdmin();
				this.visiable = true;
				this.data = this.groupDetail;
			},
			close() {
				this.visiable = false;
				
			}
		}
	}
</script>
<style lang="less">
	.group {
		.el-dialog__body {
			padding: 0 0;
		}
	}
</style>
<style lang="less" scoped>
	.select-content {
		max-height: 380px;
		overflow-y: scroll;
	}

	.group-item-space {
		width: 100%;
		background-color: #eee;
		height: 10px;
	}

	.group-item {
		width: 100%;
		padding: 0 9px 0 15px;
		box-sizing: border-box;
		cursor: pointer;
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 44px;

			p {
				color: #303133;
				display: flex;
				align-items: center;

				span {
					color: #999;
				}

				img {
					width: 40px;
					height: 40px;
					border-radius: 5px;
				}

				i {
					color: #999;
					margin-left: 4px;
					background: url(../../assets/images/jt.png);
					background-size: 100%;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
</style>
