<!-- 文本输入框 -->
<template>
  <div
    v-if="selectId !== ''"
    class="text"
    id="text"
  >
    <div class="emoji">
      <i
        class="icon iconfont icon-look"
        @click="showEmoji=!showEmoji"
      ></i>
      <i
        class="click-icon"
        @click="sendClick"
      ></i>
      <input
        id="upload_file"
        type="file"
        accept=".jpeg,.jpg,.png,.gif"
        multiple
        class="upload-file"
        @change="fileChange($event)"
      />
      <transition name="showbox">
        <div
          v-show="showEmoji"
          class="emojiBox"
        >
          <li
            v-for="(item, index) in emojis"
            :key="index"
          >
            <img
              v-if="item.title !== '戳一戳'"
              :src="'static/emoji/'+item.file"
              :data="item.code"
              @click="content +=item.code"
            >
          </li>
        </div>
      </transition>
    </div>
    <textarea
      ref="text"
      v-model="content"
      @keyup="onKeyup"
      @click="showEmoji=false"
    ></textarea>
    <div
      class="send"
      @click.stop.prevent="send"
    >
      <span>发送(ent)</span>
    </div>
    <transition name="appear">
      <div
        v-show="warn"
        class="warn"
      >
        <div class="description">不能发送空白信息</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { textMsg, upload, setListTime } from '@/api'
export default {
  data () {
    return {
      content: '',
      reply: '未找到',
      frequency: 0,
      warn: false,
      showEmoji: false
    };
  },
  computed: {
    ...mapState([
      'selectId',
      'emojis'
    ]),
    ...mapGetters([
      'selectedChat'
    ])
  },
  watch: {
    // 在选择其它对话的时候 聚焦输入框
    selectId () {
      setTimeout(() => {
        this.$refs.text.focus()
      }, 0)
      this.$nextTick(() => {
        const _this = this
        // this.$refs.text.focus()
        const box = document.getElementById('text')
        document.ondragover = () => false
        document.ondrop = () => false
        box.ondrop = (e) => {
          const files = e.dataTransfer.files
          if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
              let url = window.URL || window.webkitURL
              let img = new Image();
              let item = files[i]
              img.src = url.createObjectURL(item)

              img.onload = function () {
                console.log(item);
                upload({
                  list_id: _this.selectedChat.list_id,
                  _token: localStorage.getItem('token'),
                  file: item
                }).then(res => {
                  if (res.err == 0) {
                    let msg = {
                      content: {
                        url: res.data.save_name,
                        h: this.height,
                        w: this.width,
                        save_pic_path: ''
                      },
                      type: 2
                    }
                    textMsg({
                      list_id: _this.selectedChat.list_id,
                      _token: localStorage.getItem('token'),
                      content_type: 0,
                      content: JSON.stringify({
                        url: res.data.save_name,
                        h: this.height,
                        w: this.width,
                        save_pic_path: '',
                        type: 2
                      }),
                      _agent_id: 1
                    }).then(res => {
                      _this.$store.dispatch('selectSession', _this.selectId)
                    })
                    setListTime({
                      list_id: _this.selectedChat.list_id,
                      _token: localStorage.getItem('token'),
                      _agent_id: 1
                    })
                    _this.$store.dispatch('sendMessage', msg)
                  }

                })
              }
            }
          }
        }
      })
    },
    // 当输入框中的值为空时 弹出提示  并在一秒后消失
    content () {
      if (this.content === '') {
        if (this.frequency === 0) {
          this.warn = true;
          this.frequency++
          setTimeout(() => {
            this.warn = false;
          }, 1000)
        }
      }
    }
  },
  // 在进入的时候 聚焦输入框
  mounted () {

  },
  methods: {
    // 按回车发送信息
    onKeyup (e) {
      if (e.keyCode === 13) {
        this.send()
      }
    },
    // 点击发送按钮发送信息
    send () {
      if (this.content.length < 1) {
        this.warn = true
        this.content = ''
        setTimeout(() => {
          this.warn = false;
        }, 1000)
      } else {
        var msg = {
          content: this.content,
          type: 0
        }
        // this.$store.dispatch('sendMessage', msg)
        textMsg({
          list_id: this.selectedChat.list_id,
          _token: localStorage.getItem('token'),
          content_type: 0,
          content: JSON.stringify({
            text: this.content
          }),
          _agent_id: 1
        }).then(res => {
          this.$store.dispatch('selectSession', this.selectId)
        })
        setListTime({
          list_id: this.selectedChat.list_id,
          _token: localStorage.getItem('token'),
          _agent_id: 1
        })
        this.content = ''
      }
    },
    sendClick () {
      var msg = {
        content: '[戳一戳]',
        type: 0
      }
      this.$store.dispatch('sendMessage', msg)
      textMsg({
        list_id: this.selectedChat.list_id,
        _token: localStorage.getItem('token'),
        content_type: 0,
        content: JSON.stringify({
          text: '[戳一戳]'
        }),
        msgItem_type: 0,
        _agent_id: 1
      })
      setListTime({
        list_id: this.selectedChat.list_id,
        _token: localStorage.getItem('token'),
        _agent_id: 1
      })
    },
    fileChange (e) {
      let _this = this
      let len = e.target.files.length;

      for (var i = 0; i < len; i++) {
        let url = window.URL || window.webkitURL
        let img = new Image();
        let item = e.target.files[i]
        img.src = url.createObjectURL(item)

        img.onload = function () {
          console.log(item);
          upload({
            list_id: _this.selectedChat.list_id,
            _token: localStorage.getItem('token'),
            file: item
          }).then(res => {
            if (res.err == 0) {
              let msg = {
                content: {
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: ''
                },
                type: 2
              }
              textMsg({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                content_type: 0,
                content: JSON.stringify({
                  url: res.data.save_name,
                  h: this.height,
                  w: this.width,
                  save_pic_path: '',
                  type: 2
                }),
                _agent_id: 1
              }).then(res => {
                _this.$store.dispatch('selectSession', _this.selectId)
              })
              setListTime({
                list_id: _this.selectedChat.list_id,
                _token: localStorage.getItem('token'),
                _agent_id: 1
              })


              _this.$store.dispatch('sendMessage', msg)
            }

          })
        }
      }

    }
  }
}
</script>

<style lang="stylus" scoped>
.text
  position relative
  height 150px
  background #fff
  .emoji
    position relative
    width 100%
    height 40px
    line-height 40px
    font-size 12px
    padding 0 30px
    box-sizing border-box
    color #7c7c7c
    .icon-look
      cursor pointer
      &:hover
        color #1aad19
    .emojiBox
      position absolute
      display flex
      flex-wrap wrap
      top -210px
      left -100px
      width 300px
      height 200px
      padding 5px
      background-color #fff
      border 1px solid #d1d1d1
      border-radius 2px
      box-shadow 0 1px 2px 1px #d1d1d1
      &.showbox-enter-active, &.showbox-leave-active
        transition all 0.5s
      &.showbox-enter, &.showbox-leave-active
        opacity 0
      overflow-y auto
  .upload-file
    position relative
    width 18px
    height 15px
    padding-left 100px
    top 3px
    margin-left 10px
    box-sizing border-box
    cursor pointer
  .upload-file:before
    position absolute
    content ''
    width 18px
    height 15px
    top 0
    left 0
    background url('../../assets/images/img.png') no-repeat
    background-size 100% 100%
    display block
    z-index 666
    opacity 1
    pointer-events none
  .click-icon
    position relative
    width 17px
    height 17px
    top 3px
    margin-left 8px
    background url('../../assets/images/click.png') no-repeat
    background-size 100% 100%
    display inline-block
    cursor pointer
    &:hover
      color #1aad19
  textarea
    box-sizing border-box
    padding 0 30px
    height 110px
    width 100%
    border none
    outline none
    font-family 'Micrsofot Yahei'
    resize none
  .send
    position absolute
    cursor pointer
    bottom 10px
    right 30px
    width 75px
    height 28px
    line-height 28px
    box-sizing border-box
    text-align center
    border 1px solid #e5e5e5
    border-radius 3px
    background #f5f5f5
    font-size 14px
    color #7c7c7c
    &:hover
      background rgb(18, 150, 17)
      color #fff
  .warn
    position absolute
    bottom 50px
    right 10px
    width 110px
    height 30px
    line-height 30px
    font-size 12px
    text-align center
    border 1px solid #bdbdbd
    border-radius 4px
    box-shadow 0 1px 5px 1px #bdbdbd
    &.appear-enter-active, &.appear-leave-active
      transition all 1s
    &.appear-enter, &.appear-leave-active
      opacity 0
    &:before
      content ' '
      position absolute
      top 100%
      right 20px
      border 7px solid transparent
      border-top-color #fff
      filter drop-shadow(1px 3px 2px #bdbdbd)
</style>
