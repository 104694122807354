<!-- 聊天列表 -->
<template>
  <div class="msglist">
    <ul>
      <li
        v-for="(item,index) in searchedChatlist"
        :key="index"
        class="sessionlist"
        :class="{ active: item.list_id === selectId,
                  isTop: item.top }"
        @contextmenu.prevent="(e) => handleSet(e, item)"
        @click="selectSession(item.list_id)"
      >
        <div class="list-left">
          <img
            v-if="item.type == 0"
            class="avatar"
            width="42"
            height="42"
            :alt="item.user.name"
            :src="item.user.img"
          >
          <ul
            v-if="item.type == 1"
            class="group-avatar"
          >
            <li
              v-for="(j, _index) in item.groupImgs"
              :key="_index"
            >
              <img
                v-if="_index < 4"
                width="20"
                height="20"
                :src="photoUrl + j.photo"
              >
            </li>
          </ul>
          <p
            v-if="item.unread > 0"
            class="unread"
          >{{ item.unread }}</p>
        </div>
        <div class="list-right">
          <p class="name">{{ item.user.name }} <span v-if="item.type == 1">({{ item.groupImgs.length }})</span></p>
          <span class="time">{{ item.messages[item.messages.length-1].date | time }}</span>
          <p class="lastmsg">{{ item.messages[item.messages.length-1].content | content }}</p>
        </div>
      </li>
    </ul>
    <div
      v-show="menuVisible"
      id="contextmenu"
      class="menu"
    >
      <div
        class="contextmenu__item"
        @click="handleSetting(1, !currentRow.top)"
      >{{ currentRow.top ? '取消' : '' }}置顶</div>
      <div
        class="contextmenu__item"
        @click="handleSetting(2, !currentRow.is_disturb)"
      >{{ currentRow.is_disturb ? '打开新消息提醒' : '消息免打扰' }}</div>
      <div
        class="contextmenu__item"
        @click="handleSetting(3)"
      >删除聊天</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { getChatList, getChatDetails, msgDisturb, chatTop, deleteChat } from '@/api'
const { photoUrl } = window.__gconf
export default {
  filters: {
    // 将日期过滤为 hour:minutes
    time (timestamp) {
      timestamp = new Date(timestamp).valueOf() / 1000
      let curTimestamp = parseInt(new Date().getTime() / 1000), //当前时间戳
        timestampDiff = curTimestamp - timestamp, // 参数时间戳与当前时间戳相差秒数
        curDate = new Date(curTimestamp * 1000), // 当前时间日期对象
        tmDate = new Date(timestamp * 1000), // 参数时间戳转换成的日期对象
        Y = tmDate.getFullYear(),
        m = tmDate.getMonth() + 1, d = tmDate.getDate(),
        H = tmDate.getHours(), i = tmDate.getMinutes(),
        s = tmDate.getSeconds();
      if (timestampDiff < 60) { // 一分钟以内
        return '刚刚';
      } else if (timestampDiff < 3600) { // 一小时前之内
        return Math.floor(timestampDiff / 60) + '分钟前';
      } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
        return '今天 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
      } else {
        var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
          return '昨天 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        } else if (curDate.getFullYear() == Y) {
          return ((String(m).length == 1 ? '0' : '') + m) + '/' + ((String(d).length == 1 ? '0' : '') + d) + ' ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        } else {
          return Y + '年' + ((String(m).length == 1 ? '0' : '') + m) + '月' + ((String(d).length == 1 ? '0' : '') + d) + '日 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        }
      }
    },
    content (content) {
      if (typeof content == 'string') {
        return content
      } else if (typeof content == 'object') {
        if (content.url) {
          return '[图片]'
        } else if (content.text && typeof content.text == 'string' && content.text.includes('名片')) {
          return '[名片]'
        } else {
          return content.text
        }
      }
    }
  },
  data () {
    return {
      menuVisible: false,
      currentRow: {},
      photoUrl: photoUrl
    }
  },
  computed: {
    ...mapState([
      'selectId',
      'searchText',
      'chatlist'
    ]),
    ...mapGetters([
      'searchedChatlist'
    ])
  },
  watch: {
    searchedChatlist (searchedChatlist) {
      // console.log(searchedChatlist)
    }
  },
  created () {
    this.fetchCharList(this.selectId || '')
  },
  methods: {
    ...mapActions([
      'selectSession',
      'fetchCharList'
    ]),
    ...mapMutations(['clearSelectId']),
    handleSet (event, item) {
      console.log(item)
      getChatDetails({
        list_id: item.list_id
      }).then(res => {
        this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
        this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
        event.preventDefault() //关闭浏览器右键默认事件
        this.currentRow = {
          ...item,
          ...res.data
        }
        var menu = document.querySelector('.menu')
        this.styleMenu(menu, event)
      })
    },
    foo () {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu (menu, event) {
      if (event.clientX > 1800) {
        menu.style.left = event.clientX - 100 + 'px'
      } else {
        menu.style.left = event.clientX + 1 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (event.clientY > 700) {
        menu.style.top = event.clientY - 30 + 'px'
      } else {
        menu.style.top = event.clientY - 10 + 'px'
      }
    },
    handleSetting (type, value) {
      const data = {
        list_id: this.currentRow.list_id,
        value: value ? '1' : '0'
      }
      let fetchUrl
      if (type == 1) {
        fetchUrl = chatTop
      } else if (type == 2) {
        fetchUrl = msgDisturb
      } else {
        fetchUrl = deleteChat
      }
      fetchUrl(data).then(res => {
        this.fetchCharList('')
        if (type == 3) {
          this.clearSelectId()
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.contextmenu__item
  display block
  line-height 34px
  text-align center
.contextmenu__item:not(:last-child)
  border-bottom 1px solid rgba(0, 0, 0, 0.1)
.menu
  position absolute
  background-color #fff
  width 100px
  /* height: 106px; */
  font-size 12px
  color #444040
  border-radius 4px
  -webkit-box-sizing border-box
  box-sizing border-box
  border-radius 3px
  border 1px solid rgba(0, 0, 0, 0.15)
  box-shadow 0 6px 12px rgba(0, 0, 0, 0.175)
  white-space nowrap
  z-index 1000
.contextmenu__item:hover
  cursor pointer
  background #eee
  border-color #eee
.msglist
  height 540px
  overflow-y auto
  .sessionlist
    cursor pointer
    position relative
    display flex
    padding 12px
    transition background-color 0.1s
    font-size 0
    &.isTop
      background-color rgb(223, 223, 223)
    &:hover
      background-color rgb(220, 220, 220)
    &.active
      background-color #c4c4c4
    .avatar
      border-radius 2px
      margin-right 12px
    .group-avatar
      width 42px
      height 42px
      border-radius 2px
      margin-right 12px
    .group-avatar li
      border-radius 2px
      margin-right 1px
      margin-bottom 1px
      overflow hidden
      display inline-block
    .unread
      position absolute
      height 15px
      background-color rgb(225, 0, 0)
      border-radius 50px
      color rgb(225, 225, 225)
      font-size 14px
      top 5px
      right 190px
      z-index 99
      box-sizing border-box
      padding 0 7px
    .list-right
      position relative
      flex 1
      margin-top 4px
    .name
      display inline-block
      vertical-align top
      font-size 14px
    .time
      float right
      color #999
      font-size 10px
      vertical-align top
    .lastmsg
      position absolute
      font-size 12px
      width 130px
      height 15px
      line-height 15px
      color #999
      bottom 4px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
</style>
