<template>
	<div>
		<el-dialog title="群昵称" :visible.sync="visiable" width="360px" >
			<div class="select-content" v-if="Object.keys(data).length > 0">
				<el-input placeholder="请输入群昵称" v-model="data.group.name"></el-input>
			</div>	
			<span slot="footer" class="dialog-footer">
				<el-button
				  type="primary"
				  @click="setGroupData"
				>保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import  Vue  from 'vue'
	import { mapState, mapActions} from 'vuex'
	import { getChatDetails, setGroupData} from '@/api'
	import { Message } from 'element-ui';
	export default {
	
		data() {
			return {
				data:{},
				visiable: false,
			}
		},
		computed: {
		  ...mapState([
		    'selectId',
			'groupDetail'
		  ]),
		},
		
		created() {
			
		},
		methods: {
			...mapActions(['setGroupDetail','fetchCharList']),
			handleGroupManager() {
				this.$refs.groupManager.open()
			},
			setGroupData () {
			  setGroupData({
			    list_id: this.selectId,
				type:'name',
				content:this.data.group.name
			  }).then(res => {
					Message({
					   showClose: true,
					   message: "修改成功",
					   type: 'success',
					   duration: 1000
					})
					this.visiable = false
					this.groupDetail.group.name = this.data.group.name;
					this.setGroupDetail(this.groupDetail);
					this.fetchCharList(this.selectId || '');
			  })
			},
			
			open() {
				this.visiable = true;
				this.data = this.groupDetail;
				
	
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
