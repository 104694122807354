import { render, staticRenderFns } from "./groupTransfer.vue?vue&type=template&id=4647f5bf&scoped=true&"
import script from "./groupTransfer.vue?vue&type=script&lang=js&"
export * from "./groupTransfer.vue?vue&type=script&lang=js&"
import style0 from "./groupTransfer.vue?vue&type=style&index=0&id=4647f5bf&prod&lang=less&"
import style1 from "./groupTransfer.vue?vue&type=style&index=1&id=4647f5bf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4647f5bf",
  null
  
)

export default component.exports