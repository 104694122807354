<template>
  <div class="content">
    <div class="msglist">
      <search></search>
      <chatlist></chatlist>
    </div>
    <div class="chatbox">
      <message></message>
      <v-text></v-text>
    </div>
  </div>
</template>

<script>
import search from '@/components/search/search'
import chatlist from '@/components/chatlist/chatlist'
import message from '@/components/message/message'
import vText from '@/components/text/text'
export default {
  name: 'chat',
  components: {
    search,
    chatlist,
    message,
    vText
  },
  data () {
    return {}
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  .msglist {
    width: 250px;
    background: rgb(230, 230, 230);
  }
  .chatbox {
    border-left: 1px solid #eee;
    flex: 1;
  }
}
</style>
