<template>
	<div v-if="loaded">
		<el-dialog title="移出群成员" :visible.sync="visiable" width="360px" custom-class="group">
			<div class="select-content">
				<div class="group-item" v-if="data.list.length> 0">
					 <el-checkbox-group v-model="checked">
						<template  v-for="(detail, index) in data.list">
							<h5>{{detail.letter}}</h5>
							<li v-for="(item, i) in detail.data">
								<el-checkbox :label="item.user_id"></el-checkbox>
								
								<img :src="photoUrl + item.photo" alt="">
								<p>{{item.name}}</p>
		
							</li>
						</template>
					</el-checkbox-group>	
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button
				  type="primary"
				  @click="removeChat"
				>保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState, mapActions, mapMutations } from 'vuex'
	import { Message } from 'element-ui';
	import { getGroupAdmin, removeChat } from '@/api'
	const { photoUrl } = window.__gconf
	export default {
		data() {
			return {
				data:{},
				loaded: false,
				visiable: false,
				photoUrl: photoUrl,
				checked: []
			}
		},
		computed: {
		  ...mapState([
		    'selectId',
			'groupDetail'
		  ]),
		},
		created() {
			
		},
		methods: {
			...mapActions(['setGroupDetail','fetchCharList']),
			removeChat () {

			  removeChat({
			    list_id: this.selectId,
				users: JSON.stringify(this.checked)
			  }).then(res => {
					Message({
					   showClose: true,
					   message: "保存成功",
					   type: 'success',
					   duration: 1000
					})
					this.visiable = false
					this.fetchCharList(this.selectId || '');
					this.$emit('on-back');
				
			  })
			},
			open() {
				this.checked = [];
				getGroupAdmin({
				  list_id: this.selectId,
				  type:3
				}).then(res => {
				  this.data = res.data
				  this.visiable = true;
				  this.loaded = true;
				})
	
			}
		}
	}
</script>
<style lang="less">
	.group {
		.el-dialog__body {
			padding: 0 0;
		}
	}
</style>
<style lang="less" scoped>
	.select-content {
		max-height: 380px;
		overflow-y: scroll;
	}

	.group-item-space {
		width: 100%;
		background-color: #eee;
		height: 10px;
	}

	.group-item {
		.el-checkbox-group {
			font-size: unset !important; 
		}
		width: 100%;
		
		box-sizing: border-box;
		h5{
			display: flex;
			color: #000;
			background-color: #f7f7f7;
			padding: 7px 9px;
		}
		li {
			padding: 0 9px 0 15px;
			width: 320px;
			display: flex;
			align-items: center;
			height: 44px;
			cursor: pointer;
			
			img {
				margin-left: 8px;
				width: 40px;
				height: 40px;
				border-radius: 5px;
			}
			p{
				
				margin-left: 8px;
			}

		}
	}
</style>
