<template>
  <div
    v-if="selectedChat"
    class="message"
  >
    <header class="header">
      <div class="friendname">
        {{ selectedChat.user.name }}
        <span v-if="selectedChat.type == 1">({{ selectedChat.groupImgs.length }})</span>
        <span v-if="selectedChat.type == 0">({{ user.online === 0?'离线':'在线' }}) {{ user.ip }} {{ user.region }}</span>
      </div>
	 <div  
		class="header-option"
		@click="handleGroup"
		v-if="selectedChat.type == 1"
	  >
		群管理
	  </div>
   <!--  <div
        class="header-option"
        v-if="selectedChat.type == 1"
        @click="handleInvite"
      >邀请群成员</div> -->
    </header>
    <div
      v-show="load"
      ref="list"
      class="message-wrapper"
    >
	  <div class="notice" v-if="selectedChat.type == 1 && groupDetail && groupDetail.group.notice"><p>{{groupDetail.group.notice}}</p></div>
      <ul v-if="selectedChat.messages && selectedChat.messages[0] && !selectedChat.messages[0].firstShow" 
		:class="{mt:selectedChat.type == 1 && groupDetail && groupDetail.group.notice}"
		>
        <li
          v-for="(item, index) in selectedChat.messages"
          :key="item.list_id + index"
          class="message-item"
        >
          <template v-if="(index == 0 && !selectedChat.messages[0].firstShow) || index != 0">
            <template v-if="item.type != 1">
              <div
                v-if="item.type == 0 && (index == 0 || (item.date - selectedChat.messages[index - 1].date > (180 * 1000)))"
                class="time"
              ><span>{{ item.date | time }}</span></div>
              <div
                class="main"
                :class="{ self: item.self }"
              >
                <el-popover
                  v-if="!item.self"
                  placement="right"
                  width="300"
                  trigger="click"
                  @show="showInfo(item)"
                  @hide="showHide"
                >
                  <div class="friendInfo">
                    <div
                      v-if="info"
                      class="esInfo"
                    >
                      <div class="left">
                        <div class="people">
                          <div class="nickname">{{ info.nickname }}</div>
                          <div :class="[info.sex===1?'gender-male':'gender-female']"></div>
                        </div>
                        <div class="signature">{{ info.signature }}</div>
                      </div>
                      <div class="right">
                        <img
                          class="avatar"
                          width="60"
                          height="60"
                          :src="photoUrl + info.photo"
                        >
                      </div>
                    </div>
                    <div
                      v-if="info"
                      class="detInfo"
                    >
                      <div class="remark">
                        <span>备&nbsp&nbsp&nbsp注</span>
                        <span
                          title="点击修改备注"
                          style="cursor:pointer"
                          @click="changeRemark(info, selectedChat.user.name)"
                        >{{ selectedChat.user.name }}
                        </span>
                      </div>
                      <div class="area"><span>来&nbsp&nbsp&nbsp源</span><span>{{ info.from }}</span></div>
                      <div class="list_id"><span>微信号</span><span>{{ info.username }}</span></div>
                    </div>
                    <div
                      v-if="info"
                      title="分享名片"
                      class="tip el-icon-position"
                      @click="handleShare(item)"
                    ></div>
                  </div>
                  <img
                    slot="reference"
                    class="avatar"
                    width="36"
                    height="36"
                    :src="item.self ? user.img : (item.user_info.face ? (photoUrl + item.user_info.face) : selectedChat.user.img)"
                  />
                </el-popover>
                <img
                  v-else
                  class="avatar"
                  width="36"
                  height="36"
                  :src="item.self ? user.img : selectedChat.user.img"
                />
                <div
                  class="content"
                  :class="item.msgItem_type == 8 ? 'card' : ''"
                  @contextmenu.prevent="(e) => handleOption(e, item)"
                >
                  <div
                    v-if="item.msgItem_type == 0"
                    class="text"
                    v-html="replaceFace(item.content.text)"
                  ></div>
                  <el-image
                    v-if="item.msgItem_type == 2"
                    style="width: 166px;"
                    :src="chatUrl + selectedChat.list_id + '/' + item.content.url"
                    :preview-src-list="[chatUrl + selectedChat.list_id + '/' + item.content.url]"
                  >
                  </el-image>
                  <div
                    v-if="item.msgItem_type == 8"
                    class="send-card"
                  >
                    <div class="send-card-content">
                      <img
                        width="42"
                        height="42"
                        :src="photoUrl + item.content.face"
                        alt=""
                      >
                      <span style="margin-left: 12px;">{{ item.content.nickname }}</span>
                    </div>
                    <div class="send-card-content-text">
                      <span>名片</span>
                    </div>
                  </div>
                  <div
                    v-if="item.self && showTip"
                    class="read-state"
                  >{{ item.read ? '已读': '未读' }}</div>
                </div>
              </div>
            </template>
            <div v-if="item.type == 1">
              <div
                class="time"
                v-if="item.content.text"
              ><span>{{ item.content.text }}</span></div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div
      v-show="menuVisible"
      id="contextmenu-msg"
      class="contextmenu-msg_menu"
    >
      <div
        v-if="currentRow.self"
        class="contextmenu-msg__item"
        @click="handleSetting(1)"
      >撤回</div>
      <div
        class="contextmenu-msg__item"
        @click="handleSetting(2)"
      >收藏</div>
      <div
        class="contextmenu-msg__item"
        @click="handleSetting(3)"
      >转发</div>
    </div>
    <selectUser
      ref="selectUser"
      title="消息转发"
      tip="分别转发给"
      @handleOk="handleOk"
    ></selectUser>
    <selectUser
      ref="selectInvite"
      title="邀请新成员"
      tip=""
      :default="defaultList"
      @handleOk="handleInvited"
    ></selectUser>
    <selectUser
      ref="handleShare"
      title="分享名片"
      tip="分别转发给"
      :multiple="false"
      @handleOk="handleShared"
    ></selectUser>
	<groupInfo
	 ref="groupInfo"
	
	>
		
	</groupInfo>
  </div>
</template>

<script>
import { fetchInfo, withdraw, setRemarks, sendCard, saveStore, textMsgs, addChat } from '@/api'
import { mapGetters, mapState, mapActions } from 'vuex'
import selectUser from '@/components/selectUser'
import groupInfo from '@/components/group/groupInfo.vue'
const { photoUrl, chatUrl } = window.__gconf
export default {
  components: {
    selectUser,
	groupInfo
  },
  filters: {
    // 将日期过滤为 hour:minutes
    time (timestamp) {
      timestamp = new Date(timestamp).valueOf() / 1000
      let curTimestamp = parseInt(new Date().getTime() / 1000), //当前时间戳
        timestampDiff = curTimestamp - timestamp, // 参数时间戳与当前时间戳相差秒数
        curDate = new Date(curTimestamp * 1000), // 当前时间日期对象
        tmDate = new Date(timestamp * 1000), // 参数时间戳转换成的日期对象
        Y = tmDate.getFullYear(),
        m = tmDate.getMonth() + 1, d = tmDate.getDate(),
        H = tmDate.getHours(), i = tmDate.getMinutes(),
        s = tmDate.getSeconds();
      if (timestampDiff < 60) { // 一分钟以内
        return '刚刚';
      } else if (timestampDiff < 3600) { // 一小时前之内
        return Math.floor(timestampDiff / 60) + '分钟前';
      } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
        return '今天 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
      } else {
        var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
          return '昨天 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        } else if (curDate.getFullYear() == Y) {
          return ((String(m).length == 1 ? '0' : '') + m) + '月' + ((String(d).length == 1 ? '0' : '') + d) + '日 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        } else {
          return Y + '年' + ((String(m).length == 1 ? '0' : '') + m) + '月' + ((String(d).length == 1 ? '0' : '') + d) + '日 ' + ((String(H).length == 1 ? '0' : '') + H) + ':' + ((String(i).length == 1 ? '0' : '') + i);
        }
      }
    }
  },
  data () {
    return {
      photoUrl: photoUrl,
      chatUrl: chatUrl,
      showTip: false,
      info: null,
      menuVisible: false,
      currentRow: {},
      load: false,
      isFirst: false,
      defaultList: [],
      shareItem: {}
    }
  },
  computed: {
    ...mapGetters([
      'selectedChat',
      'messages'
    ]),
    ...mapState([
      'user',
      'emojis',
      'groupChat',
      'selectId',
	  'groupDetail'
    ])
  },
  watch: {
    // 发送信息后,让信息滚动到最下面
    'selectedChat.messages': {
      handler: function (a) {
        if (!this.isFirst) {
          this.load = false
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.list ? this.$refs.list.scrollTop = this.$refs.list.scrollHeight : ''

            this.load = true
            this.isFirst = true
          }, 0)
        })
      },
      deep: true
    },
    'selectedChat.chat_id': {
      handler: function (a) {
        this.showTip = true
      }
    }
  },
  mounted () {
	
    //  在页面加载时让信息滚动到最下面
    if (this.$refs.list)
      setTimeout(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      }, 0)
  },
  destroyed () {
    this.$store.dispatch('clearIntervalTimer')
  },
  methods: {
    ...mapActions([
      'selectFriend2',
      'fetchCharList'
    ]),
    changeRemark (item, remark) {
      this.$prompt('请输入备注信息', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputValue: remark,
        inputErrorMessage: '备注信息不能为空'
      }).then(({ value }) => {
        setRemarks({
          content: value,
          user_id: item.user_id
        }).then(res => {
          this.fetchCharList(this.selectId || '')
          // this.showInfo(item)
          // this.fetchFriendList()
        })
      })
    },
    handleInvite () {
      this.$set(this, 'defaultList', [
        ...this.selectedChat.groupImgs.map(item => item.user_id)
      ])
	  console.log(this.defaultList);
      this.$refs.selectInvite.open(this.defaultList)
    },
	handleGroup(){
		this.$refs.groupInfo.open()
	},
    showInfo (item) {
      fetchInfo({
        user_id: item.userId,
        in: 0
      }).then(res => {
        this.info = {
          ...res.data
        }
      })
    },
    handleInvited (data) {
      const users = []
      data.forEach(item => {
        if (!this.defaultList.includes(item.user_id)) {
          users.push(item.user_id)
        }
      })
      addChat({
        users: JSON.stringify(users),
        list_id: this.selectedChat.list_id
      }).then(res => {
        this.fetchCharList(this.selectId || '')
      })
    },
    handleOk (data) {
      textMsgs({
        list_ids: data.filter(item => item.list_id).map(item => {
          return item.list_id
        }).join(),
        content_type: this.currentRow.msgItem_type,
        content: JSON.stringify(this.currentRow.content)
      }).then(res => {
        this.fetchCharList(this.selectId || '')
      })
    },
    showHide () {
      setTimeout(() => {
        this.info = null
      }, 300)
    },
    handleOption (event, item) {
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() //关闭浏览器右键默认事件
      this.currentRow = {
        ...item
      }
      console.log(item)
      var menu = document.querySelector('.contextmenu-msg_menu')
      this.styleMenu(menu, event)
    },
    foo () {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu (menu, event) {
      if (event.clientX > 1800) {
        menu.style.left = event.clientX - 100 + 'px'
      } else {
        menu.style.left = event.clientX + 1 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (event.clientY > 700) {
        menu.style.top = event.clientY - 30 + 'px'
      } else {
        menu.style.top = event.clientY - 10 + 'px'
      }
    },
    handleShare (item) {
      this.shareItem = item
      this.$refs.handleShare.open()
    },
    handleShared (data) {
      sendCard({
        users: JSON.stringify([this.shareItem.userId + '']),
        list_id: data.map(item => {
          return item.list_id
        }).join()
      }).then(res => {
        this.fetchCharList(this.selectId || '')
        this.$message.success('发送成功')
      })
    },
    handleSetting (type) {
      const data = {
        list_id: this.currentRow.list_id,
        type: this.currentRow.msg_type,
        msg_id: this.currentRow.msg_id
      }
      let fetchUrl
      if (type == 2) {
        fetchUrl = saveStore
      } else if (type == 1) {
        fetchUrl = withdraw
      } else {
        this.$refs.selectUser.open()
        return
        // fetchUrl = deleteChat
      }
      fetchUrl(data).then(res => {
        if (type == 1) {
          this.$store.dispatch('selectSession', this.selectId)
        } else if (type == 2) {
          if (res.msg == '收藏成功') {
            this.$message.success('收藏成功')
          } else {
            this.$message.warning(res.msg)
          }
        }
      })
    },
    //  在发送信息之后，将输入的内容中属于表情的部分替换成emoji图片标签
    //  再经过v-html 渲染成真正的图片
    replaceFace (con) {
      if (con && con.includes('[') && con.includes(']')) {
        var emojis = this.emojis;
        var newCon = ''
        con.split(']').forEach(item => {
          if (item.lastIndexOf('[') > 0) {
            item.split('[').forEach(a => {
              if (a != '') {
                for (var i = 0; i < emojis.length; i++) {
                  if (emojis[i].code.includes(a)) {
                    newCon += '<img src="static/emoji/' + emojis[i].file + '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />';
                    return
                  } else if (i + 1 == emojis.length) {
                    newCon += a
                  }
                }
              } else {
                newCon += '['
              }
            })
          }
          if (item != '') {
            for (var i = 0; i < emojis.length; i++) {
              if (emojis[i].code.includes(item)) {
                newCon += '<img src="static/emoji/' + emojis[i].file + '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />';
                return
              }
            }
          }
        })

        return newCon;
      }
      return con;
    }
  }
}
</script>

<style lang="stylus" scoped>
.mt
 margin-top 56px
.notice 
  box-sizing border-box
  position absolute;
  width 100%;
  display flex
  justify-content center
  p
   background: #eee;
   padding: 15px;
   display: inline-block;
   border-radius: 5px;
   color: #333;
   font-size 12px
.contextmenu-msg__item
  display block
  line-height 34px
  text-align center
.contextmenu-msg__item:not(:last-child)
  border-bottom 1px solid rgba(0, 0, 0, 0.1)
.contextmenu-msg_menu
  cursor pointer
  position absolute
  background-color #fff
  width 100px
  /* height: 106px; */
  font-size 12px
  color #444040
  border-radius 4px
  -webkit-box-sizing border-box
  box-sizing border-box
  border-radius 3px
  border 1px solid rgba(0, 0, 0, 0.15)
  box-shadow 0 6px 12px rgba(0, 0, 0, 0.175)
  white-space nowrap
  z-index 1000
.contextmenu__item:hover
  cursor pointer
  background #eee
  border-color #eee
.message
  width 100%
  height 450px
  .header
    height 60px
    padding 28px 0 0 30px
    box-sizing border-box
    border-bottom 1px solid #e7e7e7
    position relative
    &-option
      position absolute
      right 10px
      top 26px
      cursor pointer
      color #409EFF
    .friendname
      font-size 18px
  .message-wrapper
    position relative
    min-height 390px
    max-height 390px
    padding 10px 15px
    box-sizing border-box
    overflow-y auto
    border-bottom 1px solid #e7e7e7
    .message-item
      margin-bottom 10px
    .group
      position absolute
      z-index 999
      background-color #e7e7e7
    .group li
      margin-right 10px
      display inline-block
    .group li img
      width 36px
      height 36px
    .message
      margin-bottom 15px
    .time
      width 100%
      font-size 12px
      margin 7px auto
      text-align center
      span
        display inline-block
        line-height 14px
        padding 4px 6px
        color #fff
        border-radius 3px
        background-color #dcdcdc
    .main
      .avatar
        float left
        margin-left 15px
        border-radius 3px
      .content
        display inline-block
        margin-left 10px
        position relative
        padding 6px 10px
        max-width 330px
        min-height 36px
        line-height 24px
        box-sizing border-box
        font-size 14px
        text-align left
        word-break break-all
        background-color #dcdcdc
        border-radius 4px
        &:before
          content ' '
          position absolute
          top 12px
          right 100%
          border 6px solid transparent
          border-right-color #dcdcdc
    .self
      text-align right
      .avatar
        float right
        margin 0 15px
      .content
        background-color #b2e281
        &.card
          background-color #fff
          border 1px solid #eee
          &:before
            border-right-color transparent
            border-left-color #eee
        &:before
          right -12px
          vertical-align middle
          border-right-color transparent
          border-left-color #b2e281
        .read-state
          position absolute
          left -30px
          top 6px
          font-size 12px
          opacity 0.4
.friendInfo
  padding 0 30px
.esInfo
  display flex
  align-items center
  padding 20px 0 20px 0
  .left
    flex 1
    .people
      .nickname
        display inline-block
        font-size 20px
        margin-bottom 16px
      .gender-male, .gender-female
        display inline-block
        width 18px
        height 18px
        vertical-align top
        margin-top 2px
    .signature
      font-size 14px
      color rgba(153, 153, 153, 0.8)
  .right
    .avatar
      border-radius 3px
.tip
  font-size 24px
  float right
  cursor pointer
.detInfo
  padding 20px 0 10px
  border-top 1px solid #e7e7e7
  .remark, .area, .list_id
    font-size 14px
    margin-top 20px
    span
      font-size 14px
      color rgba(153, 153, 153, 0.8)
      margin-right 40px
      &:last-child
        margin-right 0
  .remark
    margin-top 0
</style>
<style lang="less" scoped>
.send-card {
  width: 200px;
  display: flex;
  min-height: 25px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  flex-direction: column;
  color: #333;
}

.send-card-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  height: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.send-card-content text {
  margin-left: 10px;
}

.send-card-content-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 20upx;
}
</style>
