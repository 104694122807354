<template>
	<div>
		<el-dialog title="我在本群中的昵称" :visible.sync="visiable" width="360px">
			<div class="select-content" v-if="Object.keys(data).length > 0">
				<el-input placeholder="请输入昵称" v-model="data.my_nickname"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveGroupNickName">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		mapState,
		mapActions
	} from 'vuex'
	import {
		getChatDetails,
		saveGroupNickName
	} from '@/api'
	import {
		Message
	} from 'element-ui';
	export default {

		data() {
			return {
				data: {},
				loaded: false,
				visiable: false,
			}
		},
		computed: {
			...mapState([
				'selectId',
				'groupDetail'
			]),
		},
		created() {

		},
		methods: {
			...mapActions(['setGroupDetail','fetchCharList']),
			handleGroupManager() {
				this.$refs.groupManager.open()
			},
			saveGroupNickName() {
				saveGroupNickName({
					list_id: this.selectId,
					nickname: this.data.my_nickname
				}).then(res => {
					Message({
					 showClose: true,
						message: "修改成功",
					 type: 'success',
						duration: 1000
					})
					this.visiable = false
					this.groupDetail.my_nickname = this.data.my_nickname;
					this.setGroupDetail(this.groupDetail);
			
				})
			},

			open() {
				this.visiable = true;
				this.data = this.groupDetail;

			}
		}
	}
</script>

<style lang="less" scoped>

</style>
